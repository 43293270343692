@import "./tool";

.container{
  width: @container_width;
  margin-left: auto;
  margin-right: auto;
}

.header{
  height: @header_height;
  overflow: hidden;
  .bg(bg-header,png);
  position: relative;
  z-index: 1;
  @content_height: 118px;
  box-shadow: 2px 2px 11px rgba(216, 216, 216, 0.5);
  .logo{
    float: left;
    height: @content_height;
    line-height: @content_height;
  }
  .login-area{
    float: right;
    height: @content_height;
    line-height: @content_height;
    color: @blue;
    font-size: 0;
    .login-info{
      display: inline-block;
      vertical-align: middle;
      line-height: 1.5;
      .login-desc{
        color: @blue;
        margin-left: 5px;
        font-size: 14px;
      }
    }
  }
}

.page{
  min-height: 100%;
  padding-top: 166px;
  margin-top: -166px;
  margin-bottom: -76px;
  padding-bottom: 126px;
}

.footer{
  position: relative;
  z-index: 1;
  font-size: 12px;
  color: @light-black;
  background-color: @blue;
  border-top: 1px solid @border;
  padding-bottom: 10px;
  .footer-list{
    overflow: hidden;
    line-height: 1.5;
    & + .footer-list{
      margin-top: 5px;
    }
    li{
      float: left;
      &:first-child{
        width: 280px;
      }
      &:nth-child(2){
        width: 225px;
      }
    }
  }
  .content{
    height: 65px;
    background-color: @bg-gray;
  }
  a{
    color: @light-black;
  }
}

.page-index{
  .page-content{
    .content-item{
      margin-bottom: 20px;
    }
    .index-content1{
      height: 318px;
      .left{
        width: 615px;
        .content{
          overflow: hidden;
          padding-top: 20px;
          .slide{
            float: left;
            .img-wrap(342px,258px);
            position: relative;
            .hd{
              z-index: 2;
              position: absolute;
              right: 12px;
              bottom: 12px;
              ul{
                overflow: hidden;
                li{
                  & + li{
                    margin-left: 10px;
                  }
                  float: left;
                  cursor: pointer;
                  width: 10px;
                  height: 10px;
                  border-radius: 50px;
                  border: 1px solid @blue;
                  background-color: transparent;
                  &.on{
                    background-color: @blue;
                  }
                }
              }
            }
            .bd{
              ul{
                li{
                  a{
                    display: block;
                    .img-wrap(342px,258px);
                  }
                }
              }
            }
          }
          .news-list{
            float: right;
            width: 255px;
            height: 258px;
            border-bottom: 1px dashed @border;
            >li{
              &:first-child{
                a{
                  padding-top: 0;
                }
              }
            }
          }
        }
      }
      .right{
        width: 345px;
        .activity-list{
          padding-top: 2px;
          border-bottom: 1px dashed @border;
        }
      }
    }
    .index-content2{
      .title-wrapper{
        margin-bottom: 20px;
      }
    }
    .index-content3{
      height: 321px;
      overflow: hidden;
      .left{
        width: 615px;
        .content{
          position: relative;
          overflow: hidden;
          &:before{
            content: '';
            position: absolute;
            top: 45px;
            left: 0;
            border-top: 1px dashed @border;
            z-index: 1;
            display: inline-block;
            width: 100%;
          }
          .donate-info{
            .list-header{
              width: 290px;
            }

            .donate-table{
              width: 100%;

              tbody{
                tr{
                  width: 290px;
                  float: left;
                  .donate-user{
                    width: 210px;
                  }
                  .donate-count{
                    width: 87px;
                  }
                  &:nth-child(even){
                    float: right;
                  }
                }
              }
            }
          }
        }
      }
      .right{
        width: 345px;
        .content{
          height: 281px;
          background-color: @bg-gray;
          padding: 20px 15px;
          .icon-desc{
            margin-top: 20px;
            border-top: 1px dashed @border;
            padding-top: 20px;
            width: 100%;
            font-size: 12px;
            i{
              margin-right: 5px;
            }
            .desc{
              color: @gray;
            }
          }
        }
      }
    }
  }
}

.floating-wrapper,.floating-s{
  position: absolute;
  right: 0;
  top: 50%;
  text-align: center;
  font-size: 0;
  z-index: 2;
  .floating-b{
    .floating{
      width: 145px;
      font-size: 14px;
      border-radius: 4px;
      background-color: @light-blue;
      padding: 15px;
      .title{
        color: @blue;
        margin-bottom: 10px;
        line-height: 1.2;
      }
      .img{
        width: 115px;
        height: 115px;
        //padding: 10px;
        background-color: #fff;
        border-radius: 2px;
        overflow: hidden;
        font-size: 0;
        margin-bottom: 10px;
      }
      .desc{
        color: @light-black;
        line-height: 1.2;
      }
    }
    .icon-close{
      margin-top: 13px;
      cursor: pointer;
      position: relative;
      //&:before{
      //  content: '';
      //  position: absolute;
      //  width: 24px;
      //  height: 24px;
      //  margin-left: -12px;
      //  margin-top: -6px;
      //}
    }
  }
  .floating-s{
    cursor: pointer;
    display: none;
  }
}

.page-donate-project{
  .project-list{
    >li{
      .bg-white;
    }
  }
}


.page-donate-project-detail{
  .page-content{
    padding: 20px 20px 35px;
    background-color: #fff;
    .meta{
      margin-bottom: 20px;
      .img-info(410px,289px,20px);
      .content{
        position: relative;
        >.title{
          line-height: 1.5;
          font-size: 20px;
          color: @blue;
          margin-bottom: 20px;
        }
        .summary-list{
          margin-bottom: 20px;
          .bg(bg-donate-pannel,jpg,center,100% 100%);
          height: 80px;
          padding: 15px 0 10px;
          >li{
            width: 33.33%;
          }
        }
        .title-desc-list{
          font-size: 14px;
          >li{
            .desc{
              color: @light-black;
            }
          }
        }
        .btn{
          position: absolute;
          left: 0;
          bottom: 0;
        }
        .wechat-promotion{
          position: absolute;
          right: 0;
          bottom: 0;
          padding: 7px;
          .img-info(52px,52px,8px);
          font-size: 14px;
          color: @gray;
          border: 1px solid @border;
          border-radius: 4px;
          .wechat-promotion-img{
            position: absolute;
            right: -1px;
            top: 68px;
            display: none;
            max-width: 150px;
            max-height: 150px;
            z-index: 1;
          }
          &:hover{
            .wechat-promotion-img{
              display: inline-block;
            }
          }
        }
      }
    }
    .page-main{
      .right-side-bar{
        .list-header{
          >li{
            line-height: 40px;
            height: 40px;
          }
        }
      }
    }
  }
}

.page-main{
  .content{
    width: 620px;
    float: left;
  }
  .right-side-bar{
    width: 290px;
    float: right;
  }
}

.page-space-top{
  margin-top: 20px;
}

//捐赠页面
.page-donate{
  .page-content{
    padding: 35px 35px 60px 40px;
    .project-title{
      overflow: hidden;
      line-height: 1.5;
      padding-bottom: 20px;
      border-bottom: 1px solid @border;
      .title-desc{
        font-size: 16px;
        float: left;
        color: @black;
      }
      .title{
        font-size: 18px;
        color: @blue;
      }
    }
    .icon-desc{
      margin-left: 135px;
    }

    .field-item{
      padding: 30px 0;
      border-bottom: 1px solid @border;
      .anonymous{
        input{
          margin-left: 135px;
          margin-right: 15px;
        }
      }

      .form-group{
        &.donate-count{
          @height: 40px;
          .field-name{
            height: @height;
            line-height: @height;
          }
          .form-field{
            height: @height;
            line-height: @height;
          }
        }
        &.pay-method{
          @height: 60px;
          .field-name{
            height: @height;
            line-height: @height;
          }
          .form-field{
            height: @height;
            line-height: @height;
            .select-label{
              height: @height;
              line-height: @height;
              span{
                height: @height;
                line-height: @height;
                width: 155px;
              }
            }
          }
        }
        .need-receipt{
          input{
            margin-right: 10px;
          }
        }
        &.receipt-addr{
          .form-field{
            height: auto;
            line-height: 1.5;
            .select-wrapper{
              margin-bottom: 20px;
              font-size: 0;
              select{
                width: 250px;
                & + select{
                  margin-left: 10px;
                }
              }
            }
          }
        }
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
    .agree{
      margin-top: 30px;
    }

    .btn-submit{
      margin-top: 45px;
    }
  }
}

#login-model{
  text-align: center;
  width: 425px;
  height: 325px;
  border-radius: 4px;
  padding: 0;
  .close{
    position: absolute;
    left: 50%;
    bottom: -80px;
    margin-left: -20px;
  }
  .login-title{
    height: 55px;
    line-height: 55px;
    margin-bottom: 30px;
    font-size: 18px;
    color: @blue;
    border-bottom: 1px solid @border;
  }
  .login-area{
    background-color: @blue;
    padding: 10px;
    width: 165px;
    height: 165px;
    margin: 0 auto 30px;
    .img{
      .img-wrap(145px,145px);
    }
  }
  .desc{
    font-size: 14px;
    color: @light-black;
  }
}

.page-donate-cert{
  .page-content{

    .content{
      width: 720px;
      margin: 70px auto;
      .bg(borderdonate-cert,png,center,100% 100%);
      padding: 40px 60px 140px;
      position: relative;
      .title{
        margin-bottom: 85px;
      }

      .donate-msg{
        margin-bottom: 140px;
        font-size: 16px;
        line-height: 1.5;
        color: @black;
        .donate-user{
          margin-bottom: 35px;
        }
        .donate-project{
          padding-left: 20px;
          margin-bottom: 80px;
          .donate-amount{
            font-weight: bold;
            font-size: 18px;
          }
          .project-title{
            color: @blue;
          }
          span{
            display: inline;
          }
        }
        .date{
          margin-top: 5px;
          font-size: 12px;
        }
      }
    }
  }
  #share-model{
    height: 500px;
    .content{
      .desc{
        font-size: 14px;
        color: @light-black;
        margin-bottom: 30px;
      }
      .img{
        margin-bottom: 20px;
      }
    }
  }
}

//捐赠芳名
.page-donate-name-list{
  .page-content{
    .title-wrapper{
      padding-right: 15px;
      .bread-nav{
        height: 40px;
        >li{
          height: 40px;
        }
      }
    }
    >.content{
      padding: 25px 20px 50px;
    }
  }
}

.page-excel-download{
  .excel-list{
    >li{
      &:first-child{
        padding-top: 0;
      }
    }
  }
}

.page-donate-support{
  .main{
    .donate-info-title{
      margin-top: 20px;
      padding-left: 10px;
      font-size: 16px;
      padding-top: 10px;
      line-height: 1.5;
    }
    .donate-info-list{
      margin-bottom: 40px;
      padding: 10px;
      >li{
        & + li{
          margin-top: 10px;
        }
      }
    }
    .donate-info{
      font-size: 12px;
    }
  }
}

.page-user-index{
  .donate-info{
    background-color: @light-blue;
    height: 90px;
    margin: 0 -20px 10px;
    padding: 0 20px;
    width: 720px;
    .left,.summary-list{
      color: @blue;
      display: table-cell;
      vertical-align: middle;
    }
    .left{
      width: 340px;
      font-size: 14px;
      position: relative;
      &:after{
        content: '';
        display: inline-block;
        position: absolute;
        height: 60px;
        right: 0;
        top: 50%;
        margin-top: -30px;
        border-left: 1px solid #ccf2fb;
      }
    }
    .summary-list{
      padding-left: 40px;
      >li{
        padding: 0;
        .desc{
          font-size: 18px;
          font-weight: bold;
        }
        .title{
          color: @blue;
          font-size: 14px;
        }
      }
    }
  }
}

.layui-laydate-content td.laydate-selected{
  background-color: #dbf7fe !important;
}
.layui-laydate-footer span:hover{
  color: @blue !important;
}

.select-wrapper{
  select{
    .select-default;
  }
}


//弹出信息框，一般用于ajax信息返回
.msgBoxBackGround {
  top: 0;
  left: 0;
  position: absolute;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.6;
  z-index: 1000;
}

.msgBox {
  width: 500px !important;
  left: 50% !important;
  margin-left: -250px;
  box-sizing: border-box;
  padding: 15px;
  position: fixed;
  z-index: 1050;
  background-color: #fff;
  border-radius: 5px;
  .msgBoxTitle {
    margin-bottom: 20px;
    width: 100%;
    border-bottom: 1px solid @border;
    span {
      display: inline-block;
      font-size: 18px;
      border-bottom: 3px solid @blue;
      margin-bottom: -2px;
      height: 36px;
      line-height: 36px;
    }
  }
  .msgBoxImage {
    display: none;
  }
  .msgBoxContent {
    color: @light-black;
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 20px;
  }

  .msgBoxButtons {
    text-align: center;
    .btn {
      height: 40px;
      line-height: 40px;
      width: 140px;
      color: #fff;
      background-color: @blue;
      margin: 0 10px;
    }
  }
}

.pagination-wrap{
  font-size: 0;
  a,span{
    vertical-align: middle;
    display: inline-block;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    border-radius: 2px;
    border: 1px solid @border;
    padding: 0 10px;
    color: @light-black;
    background-color: #fff;
    & + a,& + span{
      margin-left: 2px;
    }
  }
  a{
    &.on,&:hover{
      color: @blue;
    }
  }
}

.go-to-top-wrapper{
  text-align: right;
  .go-to-top{
    margin-top: 15px;
    margin-right: 5px;
    cursor: pointer;
  }
}
