@import 'var';

.iconGen(@icon,@width:10px,@height:10px){
  background: url("@{iconDir}/@{icon}.png") no-repeat center;
  width: @width;
  height: @height;
  background-origin:content-box;
  background-size: @width @height;
}

.icon-user{
  .iconGen(icon-user,17px,16px);
}


.icon-ques-red{
  .iconGen(icon-ques-red,12px,12px);
}

.icon-close{
  .iconGen(icon-close,12px,12px);
}


.icon-right-allow{
  .iconGen(icon-right-allow,7px,12px);
}

.icon-allow-down{
  .iconGen(icon-allow-down,8px,5px);
}

.icon-donate-select{
  .iconGen(icon-donate-select,22px,22px);
}

.icon-notice-gray{
  .iconGen(icon-notice-gray,14px,14px);
}

.icon-wechat-pay{
  .iconGen(icon-wechat-pay,106px,33px);
}

.icon-alipay{
  .iconGen(icon-alipay,91px,33px);
}

.icon-union-pay{
  .iconGen(icon-union-pay,80px,51px);
}

.icon-qr-code-s{
  .iconGen(icon-qr-code-s,45px,45px);
}

.icon-close-mask{
  .iconGen(icon-close-mask,39px,37px);
}

.icon-close-mask-white{
  .iconGen(icon-close-mask-white,40px,40px);
}

.icon-to-top{
  .iconGen(icon-to-top,30px,30px);
}

