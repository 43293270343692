//所有项目的公共样式
@import "var";

.input-default{
  background-color: #f5f5f5;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  padding: 0 20px;
  border: 0;
  color: @light-black;
  font-size: 14px;
}

.bg(@src,@imgSuffix: png,@pos: center,@size: cover){
  background: url('@{imgDir}@{src}.@{imgSuffix}') no-repeat @pos;
  background-size: @size;
}

.block{
  display: block;
  width: 100%;
}

.text-center{
  text-align: center !important;
}

.text-right{
  text-align: right !important;
}

.over-hidden{
  overflow: hidden;
}

.text-overflow{
  .over-hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

strong{
  font-weight: bold;
}

em{
  font-style: italic;
}

i,span{
  display: inline-block;
  vertical-align: middle;
}

.text-limit(@lineCount){
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: @lineCount;
  word-break: break-all;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

.text-limit2{
  .text-limit(2);
}
.text-limit3{
  .text-limit(3);
}
.text-limit4{
  .text-limit(4);
}
.text-limit5{
  .text-limit(5);
}

//图片超出裁剪
.img-wrap(@width,@height: auto) {
  width: @width;
  height: @height;
  overflow: hidden;
  text-align: center;
  line-height: @height;
  font-size: 0;
}

//设置圆形图片
.avatar(@size) {
  border-radius: 50%;
  overflow: hidden;
  text-align: center;
  width: @size;
  height: @size;
  line-height: @size;
  img {
    border-radius: 50%;
  }
}

.hidden{
  display: none !important;
}

.dis-table{
  display: table;
  width: 100%;
}

.table-cell,.v-align{
  display: table-cell;
  vertical-align: middle;
}

.clear-fix{
  &:after{
    content: '';
    display: inline-block;
    clear: both;
    visibility: hidden;
    height:0;
    line-height:0;
    font-size:0;
  }
}
ul.clear-fix{
  &:after{
    display: list-item;
  }
}

.visibility-hidden{
  visibility: hidden !important;
}

//perfect-scrollbar
@ps_y_width: 10px;
.scrollbar{
  position: relative;
  overflow: hidden;
}
.perfect-scrollbar-reset.ps .ps__rail-y{
  width: @ps_y_width;
  overflow: hidden;
  .ps__thumb-y{
    right: 0;
    background-color: #fff;
  }
}
.ps__thumb-y{
  cursor: pointer;
  width: 10px;
  right: 0;
  background-color:  #dbdbdb;
  box-shadow: 0 0 5px #fff;
}
.ps__rail-y{
  opacity: 0;
  width: @ps_y_width;
  border-radius: 25px;
  &:hover{
    .ps__thumb-y{
      background-color: #dbdbdb;
      width: @ps_y_width;
    }
  }
}
.ps__rail-x{
  display: none !important;
}
.ps--active-x > .ps__rail-x, .ps--active-y > .ps__rail-y{
  background-color: #ededed;
}
//perfect-scrollbar 结束

.img-info(@width: 50px,@height: 80px,@margin-left: 20px){
  >.img{
    float: left;
    .img-wrap(@width,@height);
  }
  .content{
    margin-left: (@width + @margin-left);
    min-height: @height;
    height: @height;
    position: relative;
    display: table;
    width: calc(~"100% - @{width} - @{margin-left}");
    >.title{
      line-height: 1.5;
    }
    >.desc{
    }
  }
}

.avatar-info(@width: 50px,@margin-left: 20px){
  .img{
    float: left;
    .avatar(@width);
  }
  .content{
    margin-left: (@width + @margin-left);
    min-height: @width;
    height: @width;
    position: relative;
    display: table;
    width: calc(~"100% - @{width} - @{margin-left}");
    .title{
      line-height: 1.5;
    }
    .desc{
      line-height: 1.2;
    }
  }
}

.icon-desc{
  font-size: 14px;
  color: @light-black;
  display: table;
  .icon{
    font-size: 0;
    display: table-cell;
    vertical-align: middle;
    width: 25px;
    >i{
      vertical-align: top;
    }
  }
  >i{
  }
  .desc{
    display: table-cell;
    vertical-align: top;
    line-height: 1.5;
  }

  & + &{
    margin-top: 20px;
  }
}

.icon-desc-wrapper{
  overflow: hidden;
  .icon-desc{
    margin-top: 0;
    float: left;
    & + .icon-desc{
      margin-left: 130px;
    }
  }
  &.space-between{
    .icon-desc{
      margin-left: 0;
    }
    .icon-desc:first-child{
      float: left;
    }
    .icon-desc:last-child{
      float: right;
    }
  }
}

//标题 下划线
.title-underline{
  overflow: hidden;
  border-bottom: 1px solid @border;
  .title{
    float: left;
    font-size: 24px;
    color: @blue;
    height: 70px;
    border-bottom: 3px solid @blue;
    padding-top: 20px;
  }
}

//数字-标题  列表
.summary-list {
  overflow: hidden;
  li {
    float: left;
    box-sizing: border-box;
    padding: 0 20px;
    .title {
      line-height: 1.5;
      color: @gray;
      font-size: 14px;
      margin-bottom: 15px;
    }
    .desc {
      line-height: 1.2;
      color: @red;
      font-size: 24px;
    }
  }
  &.reverse{
    .title{
      margin-bottom: 0;
    }
    .desc{
      margin-bottom: 10px;
    }
  }
}

.offcanvas{
  position: fixed;
}

//字段名跟输入框同一行
.form-group{
  @width: 135px;
  @height: 45px;
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
  .field-name{
    color: @black;
    float: left;
    width: @width;
    padding-right: 15px;
    font-size: 14px;
    text-align: right;
    height: @height;
    line-height: @height;
    font-weight: bold;
  }
  .form-field{
    border: 0;
    margin-left: @width;
    height: @height;
    line-height: @height;
    input[type=text], input[type=password], input[type=number]{
      width: 100%;
    }
  }
}

input[type=text], input[type=password], input[type=number], .select-default {
  height: 45px;
  line-height: 45px;
  color: #666;
  font-size: 14px;
  background-color: #eeeeee;
  border: none;
  outline: none;
  padding: 0 20px;
  border-radius: 4px;
}

//字段名跟输入框不同一行
.form-wrapper{
  margin-bottom: 20px;
  .field-name{
    font-size: 16px;
    color: @light-black;
    line-height: 1.5;
    margin-bottom: 10px;
  }
  .form-field{
    height: 45px;
    line-height: 45px;
  }
}

.title-desc-list{
  font-size: 12px;
  color: @gray;
  >li{
    overflow: hidden;
    line-height: 1.2;
    & + li{
      margin-top: 8px;
    }
    >.title,>.desc{
      float: left;
    }
    >.desc{
      margin-left: 5px;
    }
  }
  &.horizontal{
    overflow: hidden;
    >li{
      margin-top: 0;
      float: left;
      & + li{
        margin-left: 65px;
      }
    }
  }
}

.tab-content{
  .tab-pane{
    display: none !important;
    &.active{
      display: block !important;
    }
  }
}

.title-line-deco{
  color: #fff;
  .text-center;
  font-size: 0;
  &:after,&:before{
    content: '';
    display: inline-block;
    height: 1px;
    width: 12px;
    background-color: #fff;
    vertical-align: middle;
  }
  span{
    font-size: 18px;
    vertical-align: middle;
    padding: 0 10px;
  }
}

.checkbox-wrapper{
  font-size: 0;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  & + &{
    margin-left: 40px;
  }
  input{
    width: 18px;
    height: 18px;
    margin-right: 15px;
    vertical-align: middle;
  }
  .desc{
    font-size: 14px;
    color: @black;
  }
}

//文章展示更多
.article-content{
  .show-more{
    display: none;
  }
}
.article-mask{
  position: relative;
  &:after{
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 30px;
    background-color: #fff;
    bottom: 0;
  }
  &.active{
    max-height: 260px;
    overflow: hidden;
    .show-more{
      display: block;
    }
  }
  .show-more{
    display: none;
    cursor: pointer;
    position: absolute;
    z-index: 2;
    bottom: 0;
    width: 100%;
    left: 0;
    height: 100px;
    line-height: 100px;
    .bg(article-mask,png);
    background-repeat: repeat-x;
    background-size: 100% 70px;
    font-size: 14px;
    color: @blue;
    span{
      line-height: 1.5;
      vertical-align: bottom;
    }
    i{
    }
  }
}

.cursor-pointer{
  cursor: pointer;
}

.clear{
  clear: both;
}

.btn-reset(@width,@height){
  width: @width;
  height: @height;
}

.fwb{
  font-weight: bold;
}

.pull-left{
  float: left !important;
}

.pull-right{
  float: right !important;
}

.pt30{
  padding-top: 30px !important;
}

.pb30{
  padding-bottom: 30px !important;
}

.mr5{
  margin-right: 5px !important;
}

.mb30{
  margin-bottom: 30px !important;;
}

.mb25{
  margin-bottom: 25px !important;;
}

.mb20{
  margin-bottom: 20px !important;;
}

.mb15{
  margin-bottom: 15px !important;;
}

.mb5{
  margin-bottom: 5px !important;;
}

.mt20{
  margin-top: 20px !important;
}

.mt60{
  margin-top: 60px !important;;
}

.mb0{
  margin-bottom: 0 !important;
}
