@import "./tool";

//以下注释用于快速复制
//.title-show-list{
//  li{
//
//    .num{
//    }
//    .desc{
//    }
//  }
//}

.btn{
  display: inline-block;
  width: 120px;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  border: 0;
  vertical-align: middle;
  padding: 0;
  transition: all .3s;
  text-align: center;
  cursor: pointer;

  &.btn-icon-text{
    font-size: 0 !important;
    cursor: pointer;
    .btn-text{
      margin-left: 5px;
      font-size: 14px;
    }
  }

  .btn-color(@bg-color,@border-color: @bg-color,@color: @bg-color){
    background-color: @bg-color;
    color: #fff;
    border: 1px solid @border-color;
    &.btn-border{
      color: @color;
      line-height: 29px;
      background-color: #fff;
      &:hover{
        background-color: @border-color;
        color: #fff;
      }
    }
  }
  &.btn-blue{
    .btn-color(@blue);
    &.btn-shadow{
      box-shadow: 0 -2px 0 0px inset #2da4c5;
    }
  }
  &.btn-gold{
    .btn-color(@gold);
    &.btn-shadow{
      box-shadow: 0 -2px 0 0px inset #f19f06;
    }
  }

  &.btn-gray{
    .btn-color(#c3c3c3);
    &.btn-shadow{
      box-shadow: 0 -2px 0 0px inset #c3c3c3;
    }
  }

  &.btn-radius{
    border-radius: 25px;
  }


  &.btn-sm{
    width: auto;
    padding: 0 10px;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
    &.btn-border{

    }
  }
  &.btn-md{
    width: 120px;
    height: 40px;
    line-height: 40px !important;
    font-size: 16px;
    &.btn-border{

    }
  }

  &.btn-block{
    width: 100%;
    display: block;
  }

  &.btn-square{
    border-radius: 0;
  }
  &.btn-radius-square{
    border-radius: 4px;
  }
}

.show-popup{
  cursor: pointer;
  position: relative;
  color: @blue;
  font-size: 14px;
  &:after{
    content: '';
    position: absolute;
    left: 0;
    bottom: -3px;
    width: 100%;
    border-bottom: 1px dashed @blue;
  }
}

.btn-group{
  font-size: 0;
  &.btn-group2{
    .btn{
      & + .btn{
        margin-left: 70px;
      }
    }
  }
}

.bg-white{
  background-color: #fff !important;
}


.text-blue{
  color: @blue !important;
}

.text-black{
  color: @black !important;
}

.text-red{
  color: @red !important;
}

.text-gray{
  color: @gray !important;
}

.text-white{
  color: #fff !important;
}

.border-bottom{
  border-bottom: 1px solid @border !important;
}

.border-top{
  border-top: 1px solid @border !important;
}

.border-left{
   border-left: 1px solid @border !important;
}

.border-right{
  border-right: 1px solid @border !important;
}

.nav{
  position: relative;
  z-index: 1;
  margin-top: -24px;
  height: 50px;
  line-height: 50px;
  background-color: @blue;
  font-size: 16px;
  text-align: center;
  >li{
    height: 100%;
    float: left;
    width: 12.5%;
    box-shadow: 0px 1px 6px rgba(4, 47, 59, 0.24);
    position: relative;
    &:after{
      content: '';
      position: absolute;
      background-color: @blue;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
    a{
      position: relative;
      z-index: 2;
      display: block;
      color: #fff;
    }
    &.active{
      background-color: #c4e2ea;
      position: relative;
      box-shadow: unset;
      &:after{
        background-color: #c4e2ea;
      }
      a{
        color: @red;
        font-weight: bold;
      }
      //&:before,&:after{
      //  content: '';
      //  position: absolute;
      //}
      //&:after{
      //  top: 0;
      //  left: 0;
      //  width: 100%;
      //  border-top: 2px solid @blue;
      //}
      //&:before{
      //  left: 50%;
      //  width:0;
      //  height:0;
      //  top: 2px;
      //  border-left: 4px solid transparent;
      //  border-right:  4px solid transparent;
      //  border-top: 3px solid @blue;
      //  z-index: 2;
      //  margin-left: -4px;
      //}
    }
  }
}

.title-wrapper{
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid @blue;
  >.title{
    float: left;
    font-size: 18px;
    color: #fff;
    padding-left: 20px;
    padding-right: 20px;
    background-color: @blue;
    border-radius: 4px 4px 0 0;
  }
  .more{
    font-size: 14px;
    color: @blue;
    float: right;
    height: 40px;
    line-height: 40px;
  }
}

.content-item{
  overflow: hidden;
  >.left{
    float: left;
  }
  >.right{
    float: right;
  }
}

.news-list{
  >li{
    & + li{
      border-top: 1px dashed @border;
    }
    &.active,&:hover{
      a{
        .title{
          color: @blue;
        }
      }
    }
    a{
      display: block;
      padding: 12px 0;
      .title{
        font-size: 16px;
        color: @light-black;
        line-height: 1.3;
        margin-bottom: 10px;
        height: 40px;
      }
      .desc{
        font-size: 12px;
        color: @gray;
        line-height: 1.2;
      }
    }
  }
}

.activity-list{
  >li{
    & + li{
      border-top: 1px dashed @border;
    }
    &:hover{
      a{
        .title{
          color: @blue;
        }
      }
    }
    a{
      display: block;
      padding: 13px 0 13px 15px;
      position: relative;
      height: 68px;
      .title{
        font-size: 14px;
        color: @light-black;
        line-height: 1.5;
        position: relative;
        &:before{
          content: '';
          position: absolute;
          left: -15px;
          top: 8px;
          width: 4px;
          height: 4px;
          background-color: @red;
        }
      }
      .desc{
        position: absolute;
        right: 0;
        bottom: 16px;
        font-size: 12px;
        color: @gray;
        line-height: 1.2;
      }
    }
  }
}

.project-list{
  overflow: hidden;
  >li{
    background-color: @bg-gray;
    float: left;
    width: 480px;
    margin-bottom: 20px;
    a{
      display: block;
      .img-info(225px,160px,0px);
    }
    &:nth-child(even){
      .pull-right;
    }
    &:hover{
      .content{
        .v-align{
          >.title{
            color: @blue;
          }
        }
      }
    }
    .content{
      width: 252px;
      padding: 0 20px 0 15px;
      font-size: 12px;
      .v-align{
        >.title{
          height: 42px;
          margin-bottom: 12px;
          font-size: 14px;
          color: @light-black;
          line-height: 1.5;
        }
        .title-desc-list{
          margin-bottom: 15px;
        }
      }

    }
  }
}

.list-header{
  overflow: hidden;
  font-size: 14px;
  color: @blue;
  >li{
    height: 45px;
    line-height: 45px;
    float: left;
  }
}

.donate-table{
  width: 100%;
  tbody{
    tr{
      border-bottom: 1px dashed @border;
      td{
        height: 45px;
        line-height: 45px;
        &:first-child{
          &:before{
            content: '';
            display: inline-block;
            border-left: 2px solid @blue;
            height: 14px;
            vertical-align: middle;
            margin-right: 10px;
            margin-left: 1px;
          }
        }
        vertical-align: middle;
        &.donate-user{
          font-size: 14px;
          color: @light-black;
        }
        &.donate-count{
          text-align: right;
          font-size: 12px;
          color: @gray;
        }
      }
    }
  }
}

.donate-name-table{
  font-size: 14px;
  text-align: left;
  color: @light-black;
  thead{
    color: @blue;
    height: 30px;
    line-height: 30px;
    tr{
      th{
        text-align: left;
      }
    }
  }
  tbody{
    .donate-user{
      width: 290px;
    }
    .donate-project{
      width: 290px;
    }
    .donate-count{
      width: 195px;
      text-align: left !important;
      font-size: 12px;
    }
    .donate-time{
      text-align: right;
      font-size: 12px;
      color: @gray;
    }
  }
}

//捐赠金额选择
.select-label{
  @width: 100px;
  @height: 40px;
  overflow: hidden;
  height: @height;
  line-height: @height;
  span{
    cursor: pointer;
    font-size: 16px;
    text-align: center;
    height: @height;
    line-height: @height - 2px;
    box-sizing: border-box;
    float: left;
    border: 1px solid @border;
    border-radius: 4px;
    width: @width;
    color: @black;
    position: relative;
    &.on,&.active{
      border: 3px solid @blue;
      line-height: @height - 6px;
      &:after{
        content: '';
        .icon-donate-select;
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
    & + span{
      margin-left: 20px;
    }
  }
}

.link-list{
  overflow: hidden;
  background-color: @bg-gray;
  padding: 15px;
  margin-bottom: -10px;
  >li{
    & + li{
      margin-left: 10px;
    }
    float: left;
    margin-bottom: 10px;
    a{
      display: block;
      .img-wrap(150px,50px);
    }
  }
}


.bg-gray{
  background-color: #f7f7f7 !important;
}

//侧边栏
.slide-bar{
  float: left;
  padding-bottom: 30px;
  background-color: #fff;
  width: 240px;
  .slide-bar-title{
    text-align: center;
    height: 60px;
    font-size: 18px;
    line-height: 60px;
    .bg(bg-slide-bar-title,png,center,cover);
    background-color: @blue;
    color: #fff;
    border-bottom: 1px solid @border;
  }
  .slide-bar-menu-list{
    font-size: 16px;
    margin-bottom: 30px;
    >li{
      border-bottom: 1px solid @border;
      &.active{
        a{
          background-color: @light-blue;
        }
      }
      a{
        color: @blue;
        position: relative;
        padding-left: 40px;
        display: block;
        height: 50px;
        line-height: 50px;
        &:before{
          content: '';
          display: inline-block;
          width: 4px;
          height: 4px;
          background-color: @blue;
          position: absolute;
          top: 50%;
          margin-top: -2px;
          left: 20px;
        }
        &:hover{
          background-color: @light-blue;
        }
        i{
          position: absolute;
          right: 25px;
          top: 50%;
          margin-top: -6px;
        }
      }
    }
  }
  .quick-menu-list{
    width: 200px;
    margin: 0 auto;
    >li{
      & + li{
        margin-top: 10px;
      }
      a{
        height: 40px;
        line-height: 38px;
      }
    }
  }
}

.page-content {
  margin-top: 20px;
  overflow: hidden;
}

.main{
  float: right;
  width: 720px;
  background-color: #fff;
  padding: 0 20px 50px;
  & + &{
    margin-top: 10px;
  }
}

.main-title{
  overflow: hidden;
  height: 45px;
  line-height: 45px;
  border-bottom: 1px solid @blue;
  .title{
    font-size: 16px;
    color: @blue;
    float: left;
    font-weight: bold;
  }
}

//面包屑导航
.bread-nav{
  float: right;
  overflow: hidden;
  >li{
    float: left;
    font-size: 0;
    & + li{
      &:before{
        content: '>';
        color: @gray;
        font-size: 14px;
        display: inline-block;
        margin-left: 5px;
        margin-right: 5px;
      }
    }
    a{
      display: inline-block;
      color: @gray;
      font-size: 14px;
    }
  }
}

.dev-list{
  >li{
    a{
      border-bottom: 1px dashed @border;
      height: 55px;
      line-height: 55px;
      display: block;
      overflow: hidden;
      .title{
        float: left;
        max-width: 590px;
        .text-overflow;
        font-size: 14px;
        color: @light-black;
      }
      .time{
        float: right;
        font-size: 12px;
        color: @gray;
      }
      &:hover{
        .title{
          color: @blue;
        }
      }
    }
  }
}

//分页
.pagination{
  text-align: center;
  font-size: 0;
  margin-top: 40px;
  &.white{
    a,span{
      .bg-white;
    }
  }
  a,span{
    font-size: 14px;
    vertical-align: middle;
    display: inline-block;
    height: 30px;
    line-height: 30px;
    border: 1px solid @border;
    color: @light-black;
    border-radius: 2px;
    padding: 0 10px;
    &.on{
      color: @blue;
    }
    & + a,& + span{
      margin-left: 2px;
    }
  }
  a{
    &:not(.disabled){
      &:hover{
        color: @blue;
      }
    }
  }
}

.article-main{
  margin-top: 30px;
  >.title{
    font-size: 18px;
    margin-bottom: 15px;
  }
  .title-desc-list{
    margin-bottom: 20px;
  }
}


.mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 998;
  display: none;
}

.modal-box {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 80%;
  height: 80%;
  padding: 0 40px;
  margin: auto;
  background: #fff;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 999;
  display: none;
  &.model-box-md{
    width: 600px;
    height: 400px;
  }
}

.modal-box .close{
  cursor: pointer;
  line-height: 70px;
}
.modal-box .close img{
  width: 20px;
}

.modal-box .modal-head h2.title{
  font-size: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e9e9e9;
}

.modal-box .content{
  position: absolute;
  top: 50px;
  margin: 40px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
}

.modal-box .content .close{
  line-height: inherit;
}

.modal-box .content h3{
  font-size: 18px;
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
}

.dynamic-list{
  >li{
    &:hover{
      a{
       .content{
         .title{
           color: @blue;
         }
       }
      }
    }
    & + li{
      margin-top: 20px;
    }
    a{
        .img-info(150px,113px,20px);
        .content{
          border-bottom: 1px dashed @border;
          .title{
            height: 60px;
            margin-bottom: 15px;
            font-size: 16px;
            line-height: 1.5;
            color: @light-black;
          }
          .desc{
            font-size: 12px;
            color: @gray;
          }
        }
      }
  }
}

.excel-list{
  &.donate-list{
    >li{
      .left{
        width: 470px;
      }
    }
  }
  >li{
    border-bottom: 1px dashed @border;
    overflow: hidden;
    padding: 15px 0;
    &:hover{
      .left{
        .title{
          color: @blue;
        }
      }
    }
    .left{
      float: left;
      .title{
        font-size: 14px;
        color: @light-black;
        margin-bottom: 10px;
        line-height: 1.5;
      }
      .desc{
        color: @gray;
        line-height: 1.2;
        font-size: 0;
        span{
          font-size: 12px;
          & + span{
            margin-left: 20px;
          }
        }
      }
    }
    .donate-count{
      float: left;
      font-size: 12px;
      line-height: 1.5;
    }
    .btn{
      float: right;
      font-size: 12px;
      width: 60px;
      height: 25px;
      line-height: 25px !important;
    }
  }
}

.donate-info-list{
  font-size: 14px;
  color: @light-black;
  >li{
    .title{
      width: 75px;
      text-align-last: justify;
    }
    & + li{
      margin-top: 20px;
    }
  }
}

.search-form{
  margin-bottom: 25px;
  @height: 35px;
  font-size: 0;
  input{
    line-height: @height;
    height: @height;
    border-radius: 0;
    border: 1px solid @border;
    vertical-align: middle;
    & + input{
      margin-left: 10px;
    }
  }
  .btn{
    margin-left: 10px;
    width: 95px;
    line-height: @height;
    height: @height;
    vertical-align: middle;
  }
}

.ossuploader-add{
  cursor: pointer;
  justify-content: center;
  i{
    width: 40px !important;;
    height: 40px !important;
  }
}

